import { useMsal } from "@azure/msal-react";
import { usePostHog } from "posthog-js/react";
import { useEffect, type PropsWithChildren } from "react";

export default function IdentifyPostHogUser({ children }: PropsWithChildren) {
  const { instance } = useMsal();
  const user = instance.getAllAccounts()[0];
  const posthog = usePostHog();

  useEffect(() => {
    posthog.identify(user.idTokenClaims?.oid, {
      name: user.name,
      email: user.username,
    });
  }, [posthog, user.idTokenClaims?.oid, user.name, user.username]);

  return children;
}
