import * as echarts from "echarts/core";
import { useMemo, useState, type ComponentProps } from "react";
import {
  DatasetComponent,
  TitleComponent,
  TooltipComponent,
  GridComponent,
  TransformComponent,
} from "echarts/components";
import EChartsRenderer from "../../helpers/ECharts/EChartsWrapper";
import { LineChart } from "echarts/charts";
import { UniversalTransition } from "echarts/features";
import { CanvasRenderer } from "echarts/renderers";
import type { EChartsOption } from "echarts";
import { Box, Grid, Typography, useColorScheme, useTheme } from "@mui/joy";
import {
  defaultTranslations,
  type TranslationsType,
} from "library-translations";
import type { EchartsEventParams } from "../shared/types/EchartsEventParams";
import colors from "../../helpers/colors";
import TooltipFormatter from "./TooltipFormater";
import { flushSync } from "react-dom";
import { createRoot } from "react-dom/client";

const translationStrings = [
  "Loading...",
  "No Data Available",
  "Depth (m)",
  "Error on loading data",
  "planned",
] as const;

export interface DepthLineChartAssetProps {
  isLoading: boolean;
  onClick?: (
    event: Event,
    data: [
      wellboreId: NonNullable<
        DepthLineChartAssetProps["wellbores"]
      >[number]["id"],
      ...NonNullable<
        DepthLineChartAssetProps["wellbores"]
      >[number]["data"][number],
    ]
  ) => void;
  wellbores?: {
    id: string;
    name: string;
    data: [date: Date, depth: number][];
  }[];
  isError?: boolean;
  translations?: TranslationsType<typeof translationStrings>;
  renderer?: NonNullable<
    ComponentProps<typeof EChartsRenderer>["opts"]
  >["renderer"];
}

const colorCategories = [
  "lightGreen",
  "lime",
  "pink",
  "orange",
  "aqua",
  "aubergine",
  "sand",
  "moss",
  "purple",
] as const;

const colorShades = [800, 600, 400] as const;

type DefaultColorShades = (typeof colorShades)[number];

type ColorShades = Record<DefaultColorShades, string>;

const colorsArray: string[] = colorCategories.flatMap((category) =>
  colorShades.map((shade) => (colors[category] as ColorShades)[shade])
);

export default function DepthLineChartAsset({
  isLoading,
  onClick,
  wellbores = [],
  translations,
  isError = false,
  renderer,
}: DepthLineChartAssetProps) {
  const theme = useTheme();
  const t = useMemo(
    () => ({ ...defaultTranslations(translationStrings), ...translations }),
    [translations]
  );
  const { colorScheme } = useColorScheme();

  const [tooltipContainer] = useState(() => {
    const element = document.createElement("div");
    const root = createRoot(element);
    return { root, element };
  });

  const options: EChartsOption = useMemo(() => {
    return {
      color: colorsArray,
      tooltip: {
        trigger: "axis",
        borderWidth: 0,
        backgroundColor: "transparent",
        extraCssText: "box-shadow: none;",
        formatter: (params) => {
          flushSync(() => {
            tooltipContainer.root.render(<TooltipFormatter param={params} />);
          });
          return tooltipContainer.element.innerHTML;
        },
      },
      xAxis: {
        showGrid: false,
        splitLine: {
          show: false,
        },
        nameLocation: "middle",
        type: "time",
        axisLine: { onZero: false, show: false },
        axisTick: {
          show: false,
        },
        axisLabel: {
          color:
            colorScheme === "dark" ? colors["grey"][100] : colors["grey"][800],
          formatter: function (value) {
            const date = new Date(value);
            if (date.getDate() === 1) {
              return date.toLocaleString("en-US", { month: "short" });
            }
            return "";
          },
        },
      },
      yAxis: {
        name: t["Depth (m)"],
        nameTextStyle: {
          fontFamily: "Inter",
          fontWeight: 300,
          fontSize: 12,
          color:
            colorScheme === "dark" ? colors["grey"][100] : colors["grey"][800],
        },
        nameLocation: "middle",
        nameGap: 50,
        inverse: true,
        min: 0,
        type: "value",
        axisLine: { onZero: false, show: false },
        splitLine: {
          lineStyle: {
            type: "dotted",
          },
        },
        axisLabel: {
          color:
            colorScheme === "dark" ? colors["grey"][100] : colors["grey"][800],
        },
      },
      grid: {
        left: 40,
        right: 30,
        height: "70%",
        containLabel: true,
      },
      legend: {
        show: true,
        data: wellbores.map((wellbore) => wellbore.name),
        bottom: 0,
        icon: "path://M2,0 A2,2 0 0 1 4,0 L8,0 A2,2 0 0 1 10,2 L10,8 A2,2 0 0 1 8,10 L2,10 A2,2 0 0 1 0,8 L0,2 A2,2 0 0 1 2,0 Z",
        itemWidth: 16,
        itemHeight: 16,
        formatter: (name) => {
          return `{name|${name}}`;
        },
        textStyle: {
          rich: {
            name: {
              width: 60,
              height: 20,
              overflow: "truncate",
              fontWeight: 300,
              fontSize: 10,
              lineHeight: 15,
              fontFamily: "Inter",
              color:
                colorScheme === "dark"
                  ? colors["grey"][100]
                  : colors["grey"][800],
            },
          },
        },
        inactiveColor: "transparent",
        inactiveBorderWidth: 1,
        inactiveBorderColor: colors["grey"][300],
      },
      series: wellbores.map((wellbore, index) => ({
        data: wellbore.data.map(([date, depth]) => [date, Math.round(depth)]),
        name: wellbore.name,
        id: wellbore.id,
        type: "line" as const,
        lineStyle: {
          width: 2,
          color: colorsArray[index % colorsArray.length],
        },
        showSymbol: false,
      })),
    };
  }, [wellbores, t, colorScheme, tooltipContainer]);

  echarts.use([
    DatasetComponent,
    TitleComponent,
    TooltipComponent,
    GridComponent,
    TransformComponent,
    LineChart,
    CanvasRenderer,
    UniversalTransition,
  ]);

  const onEvents = {
    click: (
      e: EchartsEventParams<(typeof wellbores)[number]["data"][number]>
    ) => {
      if (!e.seriesId) return;
      if (!onClick) return;
      onClick(new Event("click"), [e.seriesId, ...e.data]);
    },
  };

  if (wellbores.length === 0 || isError) {
    return (
      <Grid height={428} container justifyContent="center" alignItems="center">
        <Box>
          <Typography level="body-md" fontSize="lg" fontWeight={300}>
            {isError ? t["Error on loading data"] : t["No Data Available"]}
          </Typography>
        </Box>
      </Grid>
    );
  }

  return (
    <EChartsRenderer
      notMerge={true}
      lazyUpdate={true}
      echarts={echarts}
      option={options}
      onEvents={onEvents}
      style={{ height: 500, width: "100%" }}
      loadingOption={{
        text: t["Loading..."],
        color: "gray",
        textColor: theme.palette.common.black,
        maskColor: "rgba(255, 255, 255, 0.8)",
        showSpinner: true,
        spinnerRadius: 10,
        fontSize: 16,
        fontWeight: 300,
        fontFamily: "Inter",
      }}
      showLoading={isLoading}
      opts={{ renderer }}
    />
  );
}
