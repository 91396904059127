import type { IconDefinition } from "@fortawesome/fontawesome-common-types";
import { library } from "@fortawesome/fontawesome-svg-core";

const iconName = "hexagon-exclamation";
const width = 24;
const height = 24;
const ligatures = [] as string[];
const unicode = "a";
const svgPathData =
  "M7.5 7.75C7.5 7.84375 7.5 7.9375 7.5 8C7.53125 8.375 7.28125 8.71875 6.9375 8.8125C6.09375 9 5.5 9.75 5.5 10.625C5.5 11.6562 6.28125 12.4688 7.28125 12.5C7.28125 12.5 7.28125 12.5 7.3125 12.5H16.4688C17.5938 12.5 18.4688 11.625 18.4688 10.5C18.4688 9.40625 17.5938 8.5 16.4688 8.5C16.0938 8.5 15.75 8.21875 15.7188 7.84375C15.625 6.9375 14.9062 6.25 14 6.25C13.5312 6.25 13.0938 6.4375 12.7812 6.71875C12.625 6.875 12.4062 6.9375 12.1875 6.9375C11.9688 6.90625 11.7812 6.78125 11.6562 6.5625C11.25 5.9375 10.5312 5.5 9.75 5.5C8.5 5.5 7.5 6.53125 7.5 7.75ZM9.75 4C10.7812 4 11.75 4.46875 12.4375 5.15625C12.9062 4.90625 13.4375 4.75 14 4.75C15.4375 4.75 16.6875 5.71875 17.0938 7.0625C18.75 7.34375 20 8.78125 20 10.5C20 12.4375 18.4062 14 16.5 14H7.25C7.1875 14 7.15625 14 7.125 14C5.375 13.875 4 12.4375 4 10.625C4 9.25 4.8125 8.09375 6 7.5625C6.09375 5.59375 7.75 4 9.75 4ZM2 16.25C2 15.8438 2.3125 15.5 2.75 15.5H19.25C19.6562 15.5 20 15.8438 20 16.25C20 16.6875 19.6562 17 19.25 17H2.75C2.3125 17 2 16.6875 2 16.25ZM4 19.25C4 18.8438 4.3125 18.5 4.75 18.5H8.25C8.65625 18.5 9 18.8438 9 19.25C9 19.6875 8.65625 20 8.25 20H4.75C4.3125 20 4 19.6875 4 19.25ZM10.5 19.25C10.5 18.8438 10.8125 18.5 11.25 18.5H21.25C21.6562 18.5 22 18.8438 22 19.25C22 19.6875 21.6562 20 21.25 20H11.25C10.8125 20 10.5 19.6875 10.5 19.25Z";
const prefix = "fac";

export const Weather: IconDefinition = {
  // @ts-expect-error we're using a custom prefix.
  prefix,
  iconName,
  icon: [width, height, ligatures, unicode, svgPathData],
};

library.add(Weather);
