import type { IconDefinition } from "@fortawesome/fontawesome-common-types";
import { library } from "@fortawesome/fontawesome-svg-core";

const iconName = "hexagon-exclamation";
const width = 24;
const height = 24;
const ligatures = [] as string[];
const unicode = "a";
const svgPathData =
  "M12 5.5C9.65625 5.5 7.53125 6.75 6.34375 8.75C5.1875 10.7812 5.1875 13.25 6.34375 15.25C7.53125 17.2812 9.65625 18.5 12 18.5C14.3125 18.5 16.4375 17.2812 17.625 15.25C18.7812 13.25 18.7812 10.7812 17.625 8.75C16.4375 6.75 14.3125 5.5 12 5.5ZM12 20C9.125 20 6.5 18.5 5.0625 16C3.625 13.5312 3.625 10.5 5.0625 8C6.5 5.53125 9.125 4 12 4C14.8438 4 17.4688 5.53125 18.9062 8C20.3438 10.5 20.3438 13.5312 18.9062 16C17.4688 18.5 14.8438 20 12 20ZM10.75 14.5H11.5V12.5H10.75C10.3125 12.5 10 12.1875 10 11.75C10 11.3438 10.3125 11 10.75 11H12.25C12.6562 11 13 11.3438 13 11.75V14.5H13.25C13.6562 14.5 14 14.8438 14 15.25C14 15.6875 13.6562 16 13.25 16H10.75C10.3125 16 10 15.6875 10 15.25C10 14.8438 10.3125 14.5 10.75 14.5ZM12 10C11.4375 10 11 9.5625 11 9C11 8.46875 11.4375 8 12 8C12.5312 8 13 8.46875 13 9C13 9.5625 12.5312 10 12 10Z";
const prefix = "fac";

export const CircleInfo: IconDefinition = {
  // @ts-expect-error we're using a custom prefix.
  prefix,
  iconName,
  icon: [width, height, ligatures, unicode, svgPathData],
};

library.add(CircleInfo);
