import type { IconDefinition } from "@fortawesome/fontawesome-common-types";
import { library } from "@fortawesome/fontawesome-svg-core";

const iconName = "hexagon-exclamation";
const width = 24;
const height = 24;
const ligatures = [] as string[];
const unicode = "a";
const svgPathData =
  "M3.6678 19.6263C3.8742 19.4428 4.1902 19.4614 4.3737 19.6678L5.32 20.7324C6.0367 21.5387 7.2966 21.5387 8.0133 20.7324L8.5973 20.0754C8.9982 19.6245 9.7172 19.6744 10.0519 20.1764 10.9786 21.5666 13.0214 21.5666 13.9481 20.1764 14.2828 19.6744 15.0018 19.6245 15.4027 20.0754L15.8031 20.5259C16.6175 21.4421 18.0492 21.4421 18.8636 20.5259L19.6263 19.6678C19.8098 19.4614 20.1258 19.4428 20.3322 19.6263 20.5386 19.8098 20.5572 20.1258 20.3737 20.3322L19.611 21.1902C18.3988 22.554 16.2679 22.554 15.0557 21.1902L14.7223 20.8152C13.394 22.687 10.6061 22.687 9.2777 20.8152L8.7607 21.3968C7.6462 22.6506 5.6871 22.6506 4.5726 21.3968L3.6263 20.3322C3.4428 20.1258 3.4614 19.8098 3.6678 19.6263ZM8.4098 14 9.9098 5H9.5C9.2239 5 9 4.7761 9 4.5 9 4.2239 9.2239 4 9.5 4H14.5C14.7762 4 15 4.2239 15 4.5 15 4.7761 14.7762 5 14.5 5H14.0903L15.5903 14H8.4098ZM12.7929 5H10.9236L10.5497 7.2432 12.7929 5ZM14.4098 13H10.8151L13.946 10.217 14.4098 13ZM13.238 5.9691 13.7293 8.9169 10.978 8.2291 13.238 5.9691ZM9.639 12.7075 10.2442 9.0764 12.9601 9.7554 9.639 12.7075ZM18 15H20V14H4L4 15H6V17H7V15H17V17H18V15ZM16 16V17C16 17.5523 16.4477 18 17 18H18C18.5523 18 19 17.5523 19 17V16H20C20.5523 16 21 15.5523 21 15V14C21 13.4477 20.5523 13 20 13H4C3.4477 13 3 13.4477 3 14V15C3 15.5523 3.4477 16 4 16H5V17C5 17.5523 5.4477 18 6 18H7C7.5523 18 8 17.5523 8 17V16H16ZM3 14H4V12H6V13H7V10C7 9.4477 6.5523 9 6 9H4C3.4477 9 3 9.4477 3 10L3 14ZM4 11H6V10H4V11Z";
const prefix = "fac";

export const Wellbore: IconDefinition = {
  // @ts-expect-error we're using a custom prefix.
  prefix,
  iconName,
  icon: [width, height, ligatures, unicode, svgPathData],
};

library.add(Wellbore);
